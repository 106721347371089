import { createAction, props } from '@ngrx/store';

import {
  PL_EVENT_SOURCE,
  PL_CALENDAR_VIEW,
  PLEvent,
  PLEvaluation,
  PLEventRepeatMode,
} from '../../models';
import { featureNamespace } from '../feature.state';
import { PL_CREATE_ACTIONS } from '../helpers';

export const PLSchedulerError = createAction(
  `${featureNamespace} Error`,
  props<{ error?: string }>(),
);

export interface PLSaveEventPayload {
  event: PLEvent;
  prevEvent?: PLEvent;
  repeat: PLEventRepeatMode;
  type?: string;
  document: boolean;
  keepOpen: boolean;
  runCancel?: Function;
}

export interface PLSaveEventSuccessPayload {
  event: PLEvent;
  document: boolean;
  keepOpen: boolean;
  prevEvent?: PLEvent;
}

export const {
  initial: PLSaveEvent,
  success: PLSaveEventSuccess,
  fail: PLSaveEventFailed,
} = PL_CREATE_ACTIONS<PLSaveEventPayload, PLSaveEventSuccessPayload>(
  `${featureNamespace} Save Event`,
);

export interface PLDeleteEventPayload {
  event: PLEvent;
  deleteType: 'one' | 'following' | 'all';
  isAmendable?: boolean;
}

/**
 * Triggers an API call to delete the event,
 * If success triggers PLRemoveEvent or PLRemoveRepeatingEvent.
 *
 * @param event The Event to delete.
 */
export const PLDeleteEvent = createAction(
  `${featureNamespace} Delete Event`,
  props<PLDeleteEventPayload>(),
);
/**
 * Remove a single event from the store.
 *
 * @param uuid The ID of the event to remove.
 */
export const PLRemoveEvent = createAction(
  `${featureNamespace} Remove Event`,
  props<{ uuid: string }>(),
);
/**
 * Remove a repeating event from the store.
 *
 * @param uuid The ID of the event to remove.
 * @param following The Date of the following events to remove.
 */
export const PLRemoveRepeatingEvent = createAction(
  `${featureNamespace} Remove Repeating Event`,
  props<{ uuid: string; following?: string }>(),
);

export const PLGetEvents = createAction(
  `${featureNamespace} Get Events`,
  props<{
    source: PL_EVENT_SOURCE;
    start?: Date;
    end?: Date;
    provider?: string;
    timezone?: string;
  }>(),
);

export const PLSetCalendarView = createAction(
  `${featureNamespace} Set view`,
  props<{
    date: Date;
    unsigned?: boolean;
    viewType?: PL_CALENDAR_VIEW;
    provider?: string;
  }>(),
);

export const PLGoToCalendar = createAction(
  `${featureNamespace} Go to calendar`,
);

export const {
  initial: PLLoadEvents,
  success: PLLoadEventsSuccess,
  fail: PLLoadEventsFail,
} = PL_CREATE_ACTIONS<
  {
    source: PL_EVENT_SOURCE;
    start: Date;
    end: Date;
    provider?: string;
    timezone?: string;
  },
  { events: PLEvent[]; start: Date; end: Date }
>(`${featureNamespace} Load Events`);

export const PLLoadAppointment = createAction(
  `${featureNamespace} Load Appointment`,
  props<{ payload: string }>(),
);
export const PLSetAppointment = createAction(
  `${featureNamespace} Set Local Appointment`,
  props<{ appointment: PLEvent; document?: boolean; keepOpen?: boolean }>(),
);

export const {
  initial: PLLoadEvaluations,
  success: PLLoadEvaluationsSuccess,
  fail: PLLoadEvaluationsFail,
} = PL_CREATE_ACTIONS<
  { statusIn: string; assignedTo?: string },
  PLEvaluation[]
>(`${featureNamespace} Load Evaluations`);
